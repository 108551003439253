
import { mixins, Options } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import Notification from "@/app/ui/components/notification/index.vue";
import OverlayPanel from "primevue/overlaypanel";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import InputDimension from "@/app/ui/components/input-dimension/index.vue";
import InputAdornment from "@/app/ui/components/input-adornment/index.vue";
import {
  CargoData,
  CargoDetailStt,
  DimensionData
} from "@/domain/entities/Cargo";
import {
  formatDateWithoutTime,
  formatTimeNumber,
  CARGO_SPECIAL_TYPE,
  MAX_CARGO_FOR_PLANE,
  ellipsisString,
  convertDecimalWithComma,
  formatInputDecimalOnly2Digits,
  checkValueOnBlurInput
} from "@/app/infrastructures/misc/Utils";
import Print from "@/app/ui/views/cargo/modules/print.vue";
import { CargoController } from "@/app/ui/controllers/CargoController";
import OutGoingMixins from "@/app/ui/views/out-going-shipment/out-going-mixin";
import {
  EditCargoBagOrStt,
  BagOrSttNumber
} from "@/data/payload/api/CargoApiRequest";
import uniqBy from "lodash/uniqBy";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import { cargoTypeData } from "../modules/cargo-module";
import { ResponseBagSttDetail } from "@/domain/entities/ResponsePayload";
import { TrackingController } from "@/app/ui/controllers/TrackingController";
import isFormatLiloBag from "@/app/infrastructures/misc/common-library/isFormatLiloBag";

@Options({
  components: {
    DetailLayout,
    OverlayPanel,
    Notification,
    InputDimension,
    InputAdornment,
    Print
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess && !this.isPartial) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      next();
      this.setOpenSuccess(false);
    }
  }
})
export default class Edit extends mixins(OutGoingMixins) {
  pagination = {
    page: 1,
    limit: 50
  };
  notificationTimeout?: NodeJS.Timeout = undefined;
  get originAirport() {
    return ellipsisString(
      `${this.detailData.airportOriginCityCode} - ${this.detailData.airportOriginCityName}`,
      11
    );
  }
  get destinationAirport() {
    return ellipsisString(
      `${this.detailData.airportDestinationCityCode} - ${this.detailData.airportDestinationCityName}`,
      11
    );
  }
  mounted() {
    this.fetchDetailData();
  }

  async fetchDetailData() {
    const isPrint = false;
    await CargoController.getDetailCargo({
      cargoNo: this.id,
      isPrint: isPrint
    });
  }

  get departureDate() {
    return new Date(this.detailData.departureDate);
  }

  get arrivalDate() {
    return new Date(this.detailData.arrivalDate);
  }

  get id(): any {
    return this.$route.params.id;
  }

  get isSttEmpty(): boolean {
    return !this.sttResultFinal?.length;
  }

  get isFormValid() {
    return (
      (parseFloat(`${this.detailData.totalActualCargoGrossWeight}`.replace(",", ".")) &&
        parseFloat(
          `${this.detailData.totalActualCargoVolumeWeight}`.replace(",", ".")
        ) &&
        parseFloat(`${this.detailData.cargoActualPiece}`.replace(",", ".")) &&
        this.detailData.actualDimension.height &&
        this.detailData.actualDimension?.length &&
        this.detailData.actualDimension.width &&
        !this.isSttEmpty
      ) || (this.isSttEmpty && this.sttResult?.length)
    );
  }

  get detailData(): CargoData {
    return CargoController.cargoDetailData;
  }

  get cargoType() {
    return cargoTypeData(this.detailData.cargoType);
  }

  get sttResult(): CargoDetailStt[] {
    const sttResult: any = CargoController.cargoDetailData.detailStt;
    return sttResult;
  }

  get sttResultTable() {
    return this.sttResult.filter(item => item.action !== "remove");
  }

  get sttResultFinal() {
    const tempData: any = [];
    if (this.sttResultTable) {
      this.sttResultTable.forEach((e: any) => {
        const existingIdx = tempData.findIndex(
          (a: any) => a.baggingNumber === e.baggingNumber
        );
        if (e.baggingNumber) {
          if (existingIdx > -1) {
            tempData[existingIdx]["sttDetails"].push(e);
          } else {
            tempData.push({ ...e, sttDetails: [e] });
          }
          return;
        }
        tempData.push({ ...e, sttDetails: [e] });
      });
    }
    return tempData;
  }

  // delete stt number
  sttNumberListTrash: Array<string> = [];
  showDeleteSttNumber = false;
  sttNumberToDelete = "";
  sttNumberSuccessDelete = "";
  errorSttNumberDelete = "";
  async onValidateDeleteSttNumber(val: any) {
    const splitVal = val.split("#");
    if (splitVal?.length > 0) this.sttNumberToDelete = splitVal[0];
    this.onDeleteSttNumber();
  }

  get isSttNo() {
    return /^\d/.test(this.sttNumberToDelete); // check if sttNo or bagNo
  }

  // find index no. stt
  get indexSttNumber() {
    return this.isSttNo
      ? this.sttResult.findIndex(data => {
          return (
            (data.sttNumber === this.sttNumberToDelete ||
              data.sttElexysNumber === this.sttNumberToDelete) &&
            data.action !== "remove"
          );
        })
      : -1;
  }

  // find index no. bag
  get indexBagNumber(): number[] {
    return this.isSttNo
      ? []
      : this.sttResult.reduce((a: number[], b, i) => {
          return a.concat(
            b.baggingNumber === this.sttNumberToDelete && b.action !== "remove"
              ? i
              : []
          );
        }, []);
  }

  onDeleteSttNumber() {
    if (this.sttNumberToDelete) {
      this.errorSttNumberDelete = "";
      this.sttNumberSuccessDelete = "";
      this.sttNumberToDelete = this.sttNumberToDelete.toUpperCase();

      const removeSttNo = (index: number) => {
        this.sttNumberListTrash.push(this.sttResult[index].sttNumber);
        if (this.sttResult[index].action !== "add")
          this.sttResult[index].action = "remove";
        else this.sttResult.splice(index, 1);

        this.sttNumberSuccessDelete = this.sttNumberToDelete;
        this.sttNumberToDelete = "";
      };

      // no. stt not found
      if (this.indexSttNumber < 0 && this.indexBagNumber?.length === 0) {
        this.errorSttNumberDelete =
          this.$t("No. STT/No. Bag tidak ditemukan atau sudah terhapus");
      } else {
        this.isSttNo
          ? removeSttNo(this.indexSttNumber)
          : this.indexBagNumber.forEach(() => {
              removeSttNo(this.indexBagNumber[0]);
            });
      }

      if (this.isSttEmpty) {
        this.detailData.actualDimension = new DimensionData({
          length: 30,
          width: 20,
          height: 10
        });
        this.detailData.totalActualCargoVolumeWeight = 0;
      }
    }
  }

  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }
  get detailDistrict() {
    return LocationController.districtDetail;
  }
  get typeUser() {
    return this.detailData.partnerType === "console"
      ? "Consolidator"
      : "Subconsolidator";
  }

  get sttDetail() {
    return TrackingController.trackingSttData.sttDetail;
  }

  // handle stt number
  errorSttNumber = "";
  isValidateScan = false;

  savedSttNo = "";
  savedCargoType = "";
  savedCargoNumber = "";

  onResetForm() {
    this.scanNumber = "";
    this.form.sttNumber = "";
    this.isErrorValidationScan = false;
    this.errorSttNumber = "";
    this.isErrorTyping = false;
    // re focus
    const inputStt: any = this.$refs.inputStt;
    inputStt?.focus();
  }

  //via scan
  isScan = false;
  addScanSttNumber(val: any) {
    if (!this.isDisableAddSttNumber) {
      const splitVal = val.split("#");
      this.form.sttNumber = `${splitVal[0]}`;
      this.isScan = true;
      this.form.sttNumber && this.addSttNumberToUpdate();
    }
  }

  addSttNumber() {
    if (!this.isDisableAddSttNumber) {
      this.isScan = false;
      const splitVal = this.scanNumber.split("#");
      this.form.sttNumber = `${splitVal[0]}`;
      this.form.sttNumber && this.addSttNumberToUpdate();
    }
  }

  checkSttorBagFormatNumber(value: string) {
    const sttPatern = /^\d{2}[a-z]{2}/; // check for stt number
    const bagPattern = /^[a-z]{3}-\d+$/; // check for bag number
    return sttPatern.test(value.toLocaleLowerCase()) || bagPattern.test(value.toLocaleLowerCase());
  }

  checkSttFromLilo(value: string) {
    return isFormatLiloBag(value)
  }

  scanNumber = "";
  async addSttNumberToUpdate() {
    const isCheckTrackingSttFirst = !this.checkSttorBagFormatNumber(
      this.form.sttNumber.trim()
    ); // this is for checking if it need to use tracking stt endpoint or not

    const isSttFromLilo = this.checkSttFromLilo(this.form.sttNumber.trim());

    const inputStt = this.form.sttNumber;
    let generatedStt = this.scanNumber.trim();

    if (isCheckTrackingSttFirst && !isSttFromLilo) {
      await TrackingController.trackStt({
        sttNo: this.form.sttNumber.trim(),
        isGetSla: false,
        isSecondCall: false,
        cache: true
      }).then(isSuccess => {
        if (isSuccess) {
          this.form.sttNumber = this.sttDetail.sttNo;
          generatedStt = this.sttDetail.sttNo;
        }
      })
    }

    let bagNumber = "";
    let sttNumber = "";
    const isSttOrBag = /^\d/.test(this.form.sttNumber);

    if (!isSttOrBag) {
      bagNumber = this.form.sttNumber.trim();
    } else {
      sttNumber = this.form.sttNumber.trim();
    }
    let payload: any = {
      bagOrStt: new BagOrSttNumber(bagNumber, sttNumber),
      cargoProductType: this.detailData.cargoProductCode,
      cargoCommodity: this.detailData.cargoCommodityCode,
      cargoType: this.detailData.cargoType,
      callback: this.addStt
    };
    payload = this.isInternal
      ? { ...payload, partnerId: this.detailData.partnerId }
      : payload;
    await CargoController.appendBagOrSttNumber(payload).then(() => {
      if (isCheckTrackingSttFirst && !isSttFromLilo) {
        this.sttResult.forEach(item => {
          if (item.sttNumber.toLowerCase() == generatedStt.toLowerCase()) {
            item.sttNoRefExternal = inputStt.trim();
          }
          return item;
        });
      }
    });
  }

  addStt(fetchBagOrStt: any) {
    this.isErrorNotification = false;
    if (fetchBagOrStt) {
      const detailSTT = CargoController.bagSttDetail;
      const bagOrStt = detailSTT.bagOrStt || [];

      const checkErrorSttNeedAssessment = bagOrStt.find((item: any) =>
        ["waiting", "rejected"].includes(item.stt_assessment_status)
      );

      if (checkErrorSttNeedAssessment) {
        playNotification("error");
        this.errorSttNumber =
          "Gagal scan karena adjustment STT belum disetujui tim Lion Parcel, tunggu dihubungi CS.";
        this.messageErrorNotification =
          "Adjustment STT belum disetujui tim Lion Parcel, tunggu follow up dari CS.";

        for (const detail of detailSTT.bagOrStt) {
          this.onFailedAddStt(detail);
        }

        clearTimeout(this.notificationTimeout as NodeJS.Timeout);
        this.notificationTimeout = setTimeout(() => {
          this.notification = false;
        }, 15000);
      } else if (detailSTT.isAllowUpdateStatus) {
        const isBagOrNot = detailSTT.bagOrStt?.find(
          (item: any) => item.bag_no !== ""
        );
        const isBaggingOnTable = this.sttResultFinal?.find(
          (item: any) =>
            item.baggingNumber === detailSTT.bagOrStt[0].bag_no && isBagOrNot
        );

        const accumulate = this.baggingAccumulate(isBagOrNot, detailSTT);
        const nextcount = accumulate + this.countPcs;
        const checkMax = nextcount > this.maxBagOrSttNumber;
        if (
          checkMax &&
          this.detailData.cargoType === CARGO_SPECIAL_TYPE &&
          !isBaggingOnTable
        ) {
          this.isMaxTotalBagOrSttForPlane = true;
        } else {
          this.isMaxTotalBagOrSttForPlane = false;
          this.onProcessCargoData(detailSTT);
        }
      } else {
        playNotification("error");
        this.errorSttNumber = this.$t(detailSTT.errorMessage);
      }
    }
  }

  baggingAccumulate(isBagOrNot: any, detailSTT: ResponseBagSttDetail) {
    let accumulate = 0;
    if (isBagOrNot && this.detailData.cargoType === CARGO_SPECIAL_TYPE)
      accumulate = 1;
    else {
      accumulate = detailSTT.bagOrStt.reduce((acc: any, curr: any) => {
        return acc + curr.stt_total_piece;
      }, 0);
    }
    return accumulate;
  }

  onProcessCargoData(detailSTT: any) {
    for (const detail of detailSTT.bagOrStt) {
      const indexCurrentSttNumber = this.sttResult.findIndex(data => {
        return data.sttNumber === detail.stt_no;
      });

      if (indexCurrentSttNumber > -1) {
        if (this.sttResult[indexCurrentSttNumber].action === "remove") {
          this.sttResult[indexCurrentSttNumber].action = "";
        } else {
          playNotification("error");
          this.errorSttNumber = this.$t("No. STT sudah di input");
        }
        return;
      } else if (!detail.is_paid) {
        this.isUnpaid = true;
        this.isErrorNotification = true;
        this.messageErrorNotification =
          this.$t("Tunggu sampai customers selesai melunasi tagihan kurang bayar");
        this.errorSttNumber =
          this.$t("Terjadi kelebihan berat sehingga status tidak dapat diupdate ke Cargo.");
        playNotification("error");
      } else {
        this.pushDataStt(detail);
        playNotification("success");
      }
      this.detailSttNotification = {
        sttNumber: detail.stt_no,
        sttElexysNumber: detail.stt_no_elexys,
        product: detail.product_type,
        commodity: detail.commodity_name,
        totalPieces: detail.stt_total_piece,
        destinationCity: `${this.detailData.destinationCityCode} - ${this.detailData.destinationCityName}`
      };
      // reset form
      this.savedSttNo = this.form.sttNumber;
      this.scanNumber = "";
      this.form.sttNumber = "";
      if (!this.isErrorValidationScan) {
        this.isValidateScan = false;
        this.onEditStt();
      }
      this.notification = true;
      // re focus
      const inputStt: any = this.$refs.inputStt;
      inputStt?.focus();

      // remove notification after 15 second
      if (!this.isUnpaid) {
        clearTimeout(this.notificationTimeout as NodeJS.Timeout)
        this.notificationTimeout = setTimeout(() => {
          this.notification = false;
        }, 15000);
      }
    }
  }

  onFailedAddStt(detailSTT: any) {
    this.detailSttNotification = {
      sttNumber: detailSTT.stt_no,
      destinationCity: `${detailSTT.destination_city_code} - ${detailSTT.destination_city_name}`,
    };
    this.isErrorNotification = true;
    this.notification = true;
  }

  pushDataStt(detail: any) {
    this.sttResult.push(
      new CargoDetailStt({
        baggingNumber: detail.bag_no,
        baggingVendorNumber: detail.bag_vendor_no,
        sttNumber: detail.stt_no,
        totalPieces: detail.stt_total_piece,
        grossWeigth: detail.gross_weight,
        volumeWeight: detail.volume_weight,
        productType: detail.product_type,
        commodity: detail.commodity_name,
        action: "add",
        commodityGroupCode: detail.commodity_group_code,
        commodityGroupName: detail.commodity_group_name
      })
    );
  }

  // change cargo actual piece and total actual gross weight when add or remove stt
  onEditStt() {
    this.detailData.cargoActualPiece = this.countPcs;
    this.detailData.totalActualCargoGrossWeight = this.totalGrossWeight;
  }

  isDiffDestination = false;
  messageErrorNotification = "";
  onCloseNotification() {
    this.isUnpaid = false;
    this.isErrorNotification = false;
    this.isDiffDestination = false;
    this.errorSttNumber = "";
    this.notification = false;
  }

  get maxBagOrSttNumber() {
    return MAX_CARGO_FOR_PLANE;
  }

  maxTotalBagOrSttForPlane = false;
  get isMaxTotalBagOrSttForPlane() {
    return this.maxTotalBagOrSttForPlane;
  }

  set isMaxTotalBagOrSttForPlane(val: boolean) {
    this.maxTotalBagOrSttForPlane = val;
  }

  get totalStt() {
    return this.sttResultTable?.length;
  }

  get totalPieces() {
    return this.detailData.totalPiecesBagStt;
  }

  set totalPieces(newValue) {
    this.detailData.totalPiecesBagStt = newValue;
  }

  get totalGrossWeight() {
    return this.sttResultTable.reduce((a, b) => {
      return a + b.grossWeigth;
    }, 0);
  }
  get totalVolumeWeight() {
    return this.sttResultTable.reduce((a, b) => {
      return a + b.volumeWeight;
    }, 0);
  }

  get shcValue() {
    return (
      `${this.detailData.shcCode} - ${this.detailData.shcDescription}` || "-"
    );
  }

  // alert popup
  isErrorTyping = false;
  isErrorValidationScan = false;
  isPartial = false;
  get sttFailedUpdate() {
    return CargoController.sttFailed;
  }
  setPartial(value: boolean) {
    this.isPartial = value;
  }
  get alertPopup() {
    // if success
    if (this.openSuccess)
      return {
        onClick: () => this.onCloseSuccess(),
        title: this.$t("cargo.titlePopupEditCargo"),
        message: this.$t("cargo.subtitlePopupEditCargo", {cargoNo: CargoController.cargoIdToGeneratePdf}),
        image: "image-modal-success"
      };
    // if success partially
    if (this.isPartial)
      return {
        onClick: () => this.onCloseSuccess(),
        onDownload: () => this.onDownloadCsvFailedStt(this.sttFailedUpdate),
        title: this.$t("cargo.titlePopupEditCargo"),
        message: this.$t("cargo.subtitlePartialEditCargo", {cargoNo: CargoController.cargoIdToGeneratePdf}),
        image: "image-modal-warning"
      };
    // default is failed
    return {
      onClick: () => this.setFailed(false),
      onDownload: () => this.onDownloadCsvFailedStt(this.sttFailedUpdate),
      title: this.$t("Perubahan Gagal !"),
      message: this.$t("Pengiriman kargo gagal diubah."),
      image: "image-modal-failed"
    };
  }

  onDownloadCsvFailedStt(data: any) {
    this.downloadCsvFailedStt({
      fileName: "cargo_stt_failed.csv",
      listStt: data
    });
  }

  isUnpaid = false;
  isErrorNotification = false;

  // form model
  form = {
    sttNumber: ""
  };

  get isDisableAddSttNumber() {
    return !this.scanNumber;
  }

  get countPcs() {
    // STTs with the same bagNo counted as one, STTs without bagNo counted per totalPieces
    const sttUniqByBag = uniqBy(
      this.sttResultTable.filter(item => item.baggingNumber),
      "baggingNumber"
    );
    const sttWithoutBag = this.sttResultTable.filter((item) => !item.baggingNumber);
    const result = [...sttUniqByBag, ...sttWithoutBag].reduce((a, b) => {
      return a + (b.baggingNumber ? 1 : b.totalPieces);
    }, 0);
    return result;
  }

  calculationVolumeWeight(countPcs: number) {
    const total =
      ((this.detailData.actualDimension?.length *
        this.detailData.actualDimension.width *
        this.detailData.actualDimension.height) /
      this.onSetDimensionDivider()) * countPcs;
    this.detailData.totalActualCargoVolumeWeight =
      total < 1 && total > 0 ? "1" : Math.round(total).toString();
  }

  onSetDimensionDivider() {
    const dimensionDivider = this.detailData.cargoType === 'plane' ? this.dimensionDividerPlane : 6000
    return dimensionDivider
  }

  // convert value decimal
  convertDecimal(value: any): number | string {
    return convertDecimalWithComma(value, 2);
  }

  // panel pickup
  togglePanel(event: any) {
    const refs: any = this.$refs;
    refs.op?.toggle(event);
  }

  // notification handler
  notification = false;
  detailSttNotification: any = null;

  // table
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-10 text-center whitespace-nowrap",
        styleCustom: "align-center",
        render: (_: any, index: number) => {
          return `<div class="text-black-lp-300">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "No. Bag",
        styleHead: "w-40 text-left whitespace-nowrap",
        render: (item: CargoDetailStt) => {
          return `<div class="text-black-lp-300 whitespace-nowrap">
                  ${item.baggingVendorNumber || item.baggingNumber || "-"}
                </div>`;
        }
      },
      {
        name: "No. STT",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-3">`;
          if (item.sttDetails) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${item.sttDetails[x].sttNumber}</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${item.sttNumber}
                </div>`;
        }
      },
      {
        name: this.$t("No. Referensi"),
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-3">`;
          if (item.sttDetails) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${item.sttDetails[x].sttNoRefExternal || "-"}</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${item.sttNoRefExternal || "-"}
                </div>`;
        }
      },
      {
        name: this.$t("Total Koli"),
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-3">`;
          if (item.sttDetails) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${item.sttDetails[x].totalPieces}</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${item.totalPieces}
                </div>`;
        }
      },
      {
        name: this.$t("Berat Kotor"),
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-3">`;
          if (item.sttDetails) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${convertDecimalWithComma(
                item.sttDetails[x].grossWeigth,
                2
              )} Kg</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${convertDecimalWithComma(item.grossWeigth, 2)} Kg
                </div>`;
        }
      },
      {
        name: this.$t("Berat Dimensi"),
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-3">`;
          if (item.sttDetails) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${convertDecimalWithComma(
                item.sttDetails[x].volumeWeight,
                2
              )} Kg</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${convertDecimalWithComma(item.volumeWeight, 2)} Kg
                </div>`;
        }
      },
      {
        name: this.$t("Produk"),
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-3">`;
          if (item.sttDetails) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${item.sttDetails[x].productType}</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${item.productType}
                </div>`;
        }
      },
      {
        name: this.$t("Komoditas"),
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-3">`;
          if (item.sttDetails) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${item.sttDetails[x].commodity}</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${item.commodity}
                </div>`;
        }
      },
      {
        name: "Action",
        key: "actionable_column",
        styleHead: "w-36 text-left whitespace-no-wrap",
        showButton: () => true
      }
    ];
  }

  isShowModalDeleteConfirmation = false;
  wordingDelete = "";
  itemToDelete: any = "";
  successModalDelete = false;

  get popUpDelete() {
    if (this.successModalDelete) {
      return {
        success: {
          action: () => {
            this.setSuccessModalDelete(false);
            this.onEditStt();
          },
          text: "OK"
        },
        title: this.$t("STT Berhasil Dihapus"),
        message: this.$t("Anda dapat menambahkan STT baru ke daftar pengiriman"),
        image: "image-modal-success"
      };
    }
    if (this.isShowModalDeleteConfirmation)
      return {
        success: {
          action: () => this.isDeleteCargoItem(),
          text: this.$t("Hapus")
        },
        cancel: {
          action: () => this.actionModalDelete(false),
          text: this.$t("Batal")
        },
        title: `${this.$t("Hapus")} STT?`,
        message: `${this.$t(this.wordingDelete)}`,
        image: "badge-confirmation-general"
      };
    return {
      success: {
        action: () => {
          this.setSuccessModalDelete(false);
        },
        text: "OK"
      },
      title: this.$t("STT Berhasil Dihapus"),
      message: this.$t("Anda dapat menambahkan STT baru ke daftar pengiriman"),
      image: "image-modal-success"
    };
  }

  // custom button
  customButton = {
    outline: true,
    iconLeft: "trash-red",
    title: this.isAccountForeign ? "Delete" : "Hapus",
    textColor: "red-lp-100",
    color: "red-lp-100",
    small: false,
    customClass: "whitespace-no-wrap",
    disabled: false,
    clickFunction: (item: any) => {
      if (!item.baggingNumber) {
        this.wordingDelete =
          "STT yang Anda hapus akan hilang dari daftar pengiriman";
      } else {
        this.wordingDelete =
          "Semua STT dari No. Bag yang sama akan terhapus dari daftar pengiriman";
      }
      this.itemToDelete = item;
      this.actionModalDelete(true);
    }
  };

  actionModalDelete(bool: boolean) {
    this.isShowModalDeleteConfirmation = bool;
    this.isUnpaid = false;
  }

  setSuccessModalDelete(bool: boolean) {
    this.successModalDelete = bool;
  }

  isDeleteCargoItem() {
    for (const sttDetail of this.itemToDelete.sttDetails) {
      this.sttNumberToDelete = sttDetail.sttNumber;
      this.onValidateDeleteSttNumber(sttDetail.sttNumber);
    }
    this.actionModalDelete(false);
    this.setSuccessModalDelete(true);
    this.isUnpaid = false;
  }

  // role account user
  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  stringDecimalToDecimal(value: string | number) {
    return parseFloat(`${value}`.replace(",", "."));
  }

  get isInternal() {
    return this.dataProfile.account_type === "internal";
  }

  withPrint = false;
  withPrintLabel = false;
  countSuccess = 0;
  countFailed = 0;
  async onSave(): Promise<void> {
    this.handleConfirmationSave(false);
    let payload: any = {
      cargoNo: this.detailData.cargoNumber,
      actualTotalGrossWeight: this.stringDecimalToDecimal(
        this.detailData.totalActualCargoGrossWeight
      ),
      actualTotalVolumeWeight: this.stringDecimalToDecimal(
        this.detailData.totalActualCargoVolumeWeight
      ),
      bagOrStt: this.sttResult?.map(
        item =>
          new EditCargoBagOrStt(item.baggingNumber, item.sttNumber, item.action)
      ),
      cargoActualPiece: Number(this.detailData.cargoActualPiece),
      actualDimension: this.detailData.actualDimension
    };

    // set payload when stt is empty
    if(this.isSttEmpty) {
      Object.assign(payload, {
        actualTotalGrossWeight: 0,
        actualTotalVolumeWeight: 0,
        cargoActualPiece: 0,
        actualDimension: new DimensionData({
          length: 30,
          width: 20,
          height: 10
        })
      })
    }

    payload = this.isInternal
      ? { ...payload, partnerId: this.detailData.partnerId }
      : payload;
    const resp = await CargoController?.onEditCargo(payload);
    this.onEditResponse(resp);
    this.handleConfirmationSave(false);
  }
  onEditResponse(resp: any) {
    if (resp) {
      if (resp.totalSttFailed > 0 && resp.totalSttSuccess === 0) {
        this.countFailed = resp.totalSttFailed;
        this.setFailed(true);
        CargoController.setSttFailed(resp.listSttFailed);
      } else if (resp.totalSttFailed > 0 && resp.totalSttSuccess > 0) {
        this.countFailed = resp.totalSttFailed;
        this.countSuccess = resp.totalSttSuccess;
        this.isPartial = true;
        CargoController.setSttFailed(resp.listSttFailed);
        this.onPrint();
      } else {
        CargoController.setOpenSuccess(true);
        this.onPrint();
      }

      this.sttResult.splice(0, this.sttResult?.length);
    }
  }
  onPrint() {
    const refs: any = this.$refs;

    if (this.withPrint) {
      refs.print.printManifestV2(CargoController.cargoIdToGeneratePdf);
    }
    if (this.withPrintLabel) {
      refs.print.printData(CargoController.cargoIdToGeneratePdf, "label");
    }
  }

  formatDate(date: any) {
    date = new Date(date);
    return formatDateWithoutTime(date);
  }

  formatTimeNumber(date: any) {
    date = new Date(date);
    return formatTimeNumber(date).substring(0, 5);
  }

  numberOnly(value: string) {
    return value
      .replace(/[^0-9,.]+/, "")
      .replace(/\.+/g, ",")
      .replace(/,(.+),/g, ",$1");
  }

  formatFloat(value: string) {
    return formatInputDecimalOnly2Digits(value);
  }

  formatValue(value: string, type: string) {
    if (checkValueOnBlurInput(value) === "withoutComma") {
      const newValue = Number(value).toFixed(2).replace(".", ",");
      if (type === "grossWeight") {
        this.detailData.totalActualCargoGrossWeight = newValue;
      } else {
        this.detailData.totalActualCargoVolumeWeight = newValue;
      }
    } else if (checkValueOnBlurInput(value) === "oneValueAfterComma") {
      if (type === "grossWeight") {
        this.detailData.totalActualCargoGrossWeight = value + "0";
      } else {
        this.detailData.totalActualCargoVolumeWeight = value + "0";
      }
    } else if (checkValueOnBlurInput(value) === "minimumValue") {
      const newValueMinimum = value.split(",")[0];
      if (type === "grossWeight") {
        this.detailData.totalActualCargoGrossWeight = newValueMinimum + ",10";
      } else {
        this.detailData.totalActualCargoVolumeWeight = newValueMinimum + ",10";
      }
    }
  }

  createAndPrint(withPrint: boolean, label = false) {
    const refs: any = this.$refs;
    refs.op?.hide();
    this.withPrint = withPrint;
    this.withPrintLabel = label;
    this.handleConfirmationSave(true);
  }

  // handle route leave and modal
  // navigation
  get openSuccess(): boolean {
    return CargoController.isOpenSuccess;
  }
  setOpenSuccess(value: boolean) {
    CargoController.setOpenSuccess(value);
  }

  // failed for create
  get isFailed(): boolean {
    return CargoController.isFailed;
  }
  setFailed(value: boolean) {
    CargoController.setFailed(value);
  }

  openConfirmationLeave = false;
  openConfirmationSave = false;
  answer = false;
  newPath = "";
  handleConfirmationLeave(value: boolean) {
    this.openConfirmationLeave = value;
  }
  handleConfirmationSave(value: boolean) {
    this.openConfirmationSave = value;
  }
  goBack() {
    this.$router.push(`${this.$route.matched[0].path}/${this.id}`);
  }
  onOpenClosePage(to: any) {
    this.handleConfirmationLeave(true);
    this.newPath = to.path;
  }
  handlerClose() {
    this.handleConfirmationLeave(false);
    this.answer = true;
    this.$router.push(this.newPath);
  }
  handlerCancelClose() {
    this.answer = false;
    this.handleConfirmationLeave(false);
  }
  onCloseSuccess() {
    this.setOpenSuccess(false);
    this.answer = true;
    this.goBack();
  }

  get isAccountForeign() {
    return AccountController.accountData.accountIsForeign;
  }

  get dimensionDividerPlane() {
    return CargoController.dimensionDivider;
  }
}
